"use client";

import { Doc } from "@/.contentlayer/generated";
import TechStack from "@/components/tech-stack";
import { buttonVariants } from "@/components/ui/button";
import { Separator } from "@/components/ui/separator";
import IconCloud from "@/registry/components/magicui/icon-cloud";


import { cn } from "@/lib/utils";
import BentoDemo from "@/registry/components/example/bento-demo";
import DockDemo from "@/registry/components/example/dock-demo";
import RetroGridDemo from "@/registry/components/example/retro-grid-demo";
import AnimatedGradientText from "@/registry/components/magicui/animated-gradient-text";
import { BorderBeam } from "@/registry/components/magicui/border-beam";
import { VelocityScroll } from "@/registry/components/magicui/scroll-based-velocity";
import { motion, useInView } from "framer-motion";


import { ChevronRight } from "lucide-react";
import Link from "next/link";
import { useRef } from "react";

interface HeroClientProps {
  post: Doc;
  numPeople?: number;
  className?: string;
}
export default function HeroClient({
  post,
  numPeople,
  className,
}: HeroClientProps) {
  const fadeInRef = useRef(null);
  const fadeInInView = useInView(fadeInRef, {
    once: true,
  });

  const fadeUpVariants = {
    initial: {
      opacity: 0,
      y: 24,
    },
    animate: {
      opacity: 1,
      y: 0,
    },
  };
  const slugs = [
    "typescript",
    "javascript",
    "dart",
    "java",
    "react",
    "flutter",
    "android",
    "html5",
    "css3",
    "nodedotjs",
    "express",
    "nextdotjs",
    "prisma",
    "amazonaws",
    "postgresql",
    "firebase",
    "nginx",
    "vercel",
    "testinglibrary",
    "jest",
    "cypress",
    "docker",
    "git",
    "jira",
    "github",
    "gitlab",
    "visualstudiocode",
    "androidstudio",
    "sonarqube",
    "figma",
  ];
  return (
    <section id="hero">
      <div className="relative h-full overflow-hidden py-5 md:py-14">
        <div className="z-10 flex flex-col">
          <div className="mt-10 grid grid-cols-1 md:mt-20">
            <div className="flex flex-col items-start gap-6 px-7 pb-8 text-center md:items-center md:px-10">
              <Link href="https://fediraly.org">
                <AnimatedGradientText>
                  <div
                    className={cn(
                      `absolute inset-0 block h-full w-full animate-gradient bg-gradient-to-r from-[#ffaa40]/50 via-[#9c40ff]/50 to-[#ffaa40]/50 bg-[length:var(--bg-size)_100%] [border-radius:inherit] [mask:linear-gradient(#fff_0_0)_content-box,linear-gradient(#fff_0_0)]`,
                      `p-[1px] ![mask-composite:subtract]`,
                    )}
                  />
                  🎉 <Separator className="mx-2 h-4" orientation="vertical" />
                  <span
                    className={cn(
                      `animate-gradient bg-gradient-to-r from-[#ffaa40] via-[#9c40ff] to-[#ffaa40] bg-[length:var(--bg-size)_100%] bg-clip-text text-transparent`,
                      `inline`,
                    )}
                  >
                    FEDIRALY Short Links Now Live! 🚀
                  </span>
                  <ChevronRight className="ml-1 h-4 w-4 text-gray-500" />
                </AnimatedGradientText>
              </Link>
              <div className="relative flex flex-col gap-4 md:items-center lg:flex-row">
                <h1 className="relative mx-0 max-w-[43.5rem] text-balance bg-gradient-to-br from-black from-50% to-neutral-200/60 bg-clip-text pt-5 text-left text-5xl font-semibold tracking-tighter text-transparent dark:text-white sm:text-7xl md:mx-auto md:px-4 md:py-2 md:text-center md:text-7xl lg:text-7xl">
                  FEDIRALY Innovating Today, Building Tomorrow
                </h1>
                <span className="text-neutral-90 absolute -top-3.5 left-0 z-10 rotate-3 whitespace-nowrap rounded-full bg-neutral-800 px-2.5 py-1 text-[11px] font-semibold uppercase leading-5 tracking-wide text-white md:top-12 md:-rotate-12">
                  Launch globally
                </span>
              </div>

              <p className="max-w-xl text-balance text-left text-base tracking-tight text-black dark:font-medium dark:text-white md:text-center md:text-lg ">
                The FEDIRALY.NET serves as the central hub and{" "}


                <span className="font-bold text-black dark:text-white">
                  powering a growing network of ventures.
                </span>
                .
                <br />
              </p>

              <div className="mx-0 flex w-full max-w-full flex-col gap-4 py-1 sm:max-w-lg sm:flex-row md:mx-auto">
                <div className="flex w-full flex-col gap-2 sm:flex-row sm:gap-4">
                  <Link
                    href="https://fediraly.link"
                    className={cn(
                      buttonVariants({
                        variant: "default",
                        size: "lg",
                      }),
                      "gap-2 whitespace-pre md:flex",
                      "group relative w-full gap-1 rounded-full text-sm font-semibold tracking-tighter ring-offset-inherit transition-all duration-150 ease-in-out hover:ring-2 hover:ring-black hover:ring-offset-2 hover:ring-offset-current dark:hover:ring-neutral-50",
                    )}
                  >
                    FEDIRALY LINK
                    <ChevronRight className="ml-1  size-4 flex-shrink-0 transition-all duration-300 ease-out group-hover:translate-x-1" />
                  </Link>
                  <Link
                    href="https://fediraly.org"
                    className={cn(
                      buttonVariants({
                        size: "lg",
                        variant: "outline",
                      }),
                      "gap-2 whitespace-pre md:flex",
                      "group relative w-full gap-1 overflow-hidden rounded-full text-sm font-semibold tracking-tighter transition-all duration-150 ease-in-out hover:ring-2 hover:ring-neutral-300 hover:ring-offset-2 hover:ring-offset-inherit dark:hover:ring-black dark:hover:ring-offset-black ",
                    )}
                  >
                    FEDIRALY Short Links
                    <ChevronRight className="ml-1 size-4 flex-shrink-0 transition-all duration-300 ease-out group-hover:translate-x-1" />
                  </Link>
                </div>
              </div>
            </div>
          </div>

          <div className="relative mx-auto flex w-full max-w-[16rem] items-center justify-center">
            <TechStack
              className="mx-auto flex w-full items-center justify-between"
              technologies={[
                // "nextjs",
                "react",
                "typescript",
                "tailwindcss",
                "framermotion",
                // "shadcn",
              ]}
            />

          </div>
          <br />
          <motion.div
            className="relative mx-auto flex w-full max-w-[1000px] items-center justify-center"
            animate={"animate"}
            variants={fadeUpVariants}
            initial={false}
            transition={{
              duration: 0.6,
              delay: 0.2,
              ease: [0.21, 0.47, 0.32, 0.98],
              type: "spring",
            }}
          >

            <div className="relative rounded-xl">
              <img
                src="/FEDIRALY.png"
                alt="Hero Image"
                className="hidden w-[700px] rounded-[inherit] border object-contain shadow-lg dark:block"
              />
              <img
                src="/FEDIRALY.png"
                alt="Hero Image"
                className="block w-[700px] rounded-[inherit] border object-contain shadow-lg dark:hidden"
              />


              <BorderBeam size={250} duration={12} delay={9} />
            </div>
          </motion.div>
          <div className="container relative mx-auto mt-32 w-full max-w-[1000px]">

            <motion.span
              animate={["initial"]}
              whileHover={["hover"]}
              variants={{
                hover: {
                  scale: 1.1,
                  rotate: -6,
                  transition: {
                    duration: 0.2,
                  },
                },
                initial: {
                  y: [-8, 8],
                  transition: {
                    duration: 2,
                    repeat: Infinity,
                    repeatType: "reverse",
                  },
                },
              }}
              className="absolute -top-16 left-0 right-auto cursor-pointer lg:-top-20"
            >

              <span className="flex items-center">
                <span className="mt-3 inline-block whitespace-nowrap rounded-full bg-neutral-800 px-4 py-1.5 text-[12px] font-semibold uppercase leading-5 tracking-wide text-white">
                  A FEDIRALY, INC. VENTURE!
                </span>
                <svg
                  className="mr-6 h-8 w-14 [transform:rotateY(180deg)rotateX(0deg)]"
                  width="45"
                  height="25"
                  viewBox="0 0 45 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M43.2951 3.47877C43.8357 3.59191 44.3656 3.24541 44.4788 2.70484C44.5919 2.16427 44.2454 1.63433 43.7049 1.52119L43.2951 3.47877ZM4.63031 24.4936C4.90293 24.9739 5.51329 25.1423 5.99361 24.8697L13.8208 20.4272C14.3011 20.1546 14.4695 19.5443 14.1969 19.0639C13.9242 18.5836 13.3139 18.4152 12.8336 18.6879L5.87608 22.6367L1.92723 15.6792C1.65462 15.1989 1.04426 15.0305 0.563943 15.3031C0.0836291 15.5757 -0.0847477 16.1861 0.187863 16.6664L4.63031 24.4936ZM43.7049 1.52119C32.7389 -0.77401 23.9595 0.99522 17.3905 5.28788C10.8356 9.57127 6.58742 16.2977 4.53601 23.7341L6.46399 24.2659C8.41258 17.2023 12.4144 10.9287 18.4845 6.96211C24.5405 3.00476 32.7611 1.27399 43.2951 3.47877L43.7049 1.52119Z"
                    fill="currentColor"
                    className="fill-gray-300 dark:fill-gray-700"
                  />
                </svg>
              </span>
            </motion.span>

            <BentoDemo />

            <div className="mt-4 grid w-full grid-cols-1 place-items-center justify-center gap-4 lg:grid-cols-2">


              <RetroGridDemo />

              <IconCloud iconSlugs={slugs} />
              <div className="relative flex h-full w-full max-w-[32rem] items-center justify-center overflow-hidden rounded-lg border bg-background py-4 md:shadow-xl">
                <VelocityScroll
                  text="Velocity Scroll"
                  default_velocity={5}
                  className="font-display text-center text-4xl font-bold tracking-[-0.02em] text-black drop-shadow-sm dark:text-white md:text-7xl md:leading-[5rem]"
                />
              </div>
              <DockDemo />
            </div>
            <div className="flex justify-center items-center min-h-screen bg-white dark:bg-black">
              <div className="max-w-2xl w-full text-center p-8 rounded-lg  relative bg-white-100 dark:bg-black text-black dark:text-white">
                <blockquote className="text-lg md:text-xl font-medium mb-4">
                  "We bring ideas to life. Our mission is to deeply understand our customers' desires and deliver cutting-edge solutions that elevate their experiences. We strive for excellence and are fueled by a passion to create meaningful change."
                  <div className="flex justify-center items-center mt-4">
                    <img
                      className="h-14 w-14 rounded-full mx-auto"
                      src="https://pbs.twimg.com/profile_images/1795291647002968064/076WebKK_400x400.jpg"
                      alt="Profile picture"
                    />
                  </div>
                  <div className="flex items-center justify-center text-lg mt-3 font-semibold text-black dark:text-white">
                    Achraf RAZZOUQI
                    <svg
                      aria-label="Verified Account"
                      viewBox="1 0 23 23"
                      className="ml-1 h-4 w-5 fill-blue-500 dark:fill-blue-300"
                    >
                      <g>
                        <path d="M22.5 12.5c0-1.58-.875-2.95-2.148-3.6.154-.435.238-.905.238-1.4 0-2.21-1.71-3.998-3.818-3.998-.47 0-.92.084-1.336.25C14.818 2.415 13.51 1.5 12 1.5s-2.816.917-3.437 2.25c-.415-.165-.866-.25-1.336-.25-2.11 0-3.818 1.79-3.818 4 0 .494.083.964.237 1.4-1.272.65-2.147 2.018-2.147 3.6 0 1.495.782 2.798 1.942 3.486-.02.17-.032.34-.032.514 0 2.21 1.708 4 3.818 4 .47 0 .92-.086 1.335-.25.62 1.334 1.926 2.25 3.437 2.25 1.512 0 2.818-.916 3.437-2.25.415.163.865.248 1.336.248 2.11 0 3.818-1.79 3.818-4 0-.174-.012-.344-.033-.513 1.158-.687 1.943-1.99 1.943-3.484zm-6.616-3.334l-4.334 6.5c-.145.217-.382.334-.625.334-.143 0-.288-.04-.416-.126l-.115-.094-2.415-2.415c-.293-.293-.293-.768 0-1.06s.768-.294 1.06 0l1.77 1.767 3.825-5.74c.23-.345.696-.436 1.04-.207.346.23.44.696.21 1.04z" />
                      </g>
                    </svg>
                  </div>

                  <div className="text-sm text-gray-600 dark:text-gray-300">
                    CEO at FEDIRALY and creator of (FEDIRALY.LINK)
                  </div>
                </blockquote>
                <div className="flex items-center mt-4"></div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>
  );
}