"use client";

import RetroGrid from "@/registry/components/magicui/retro-grid";

const RetroGridDemo = () => {
  return (
    <div className="relative flex h-full w-full max-w-[32rem] items-center justify-center overflow-hidden rounded-lg border bg-background p-20 md:shadow-xl">
      <span className="pointer-events-none z-10 whitespace-pre-wrap bg-gradient-to-b from-[#ffd319] via-[#ff2975] to-[#8c1eff] bg-clip-text text-center text-7xl font-bold leading-none tracking-tighter text-transparent">
        FEDIRALY<br/>
        LINK
      </span>

      <RetroGrid />
    </div>
  );
};

export default RetroGridDemo;
