"use client";
import { CardStack } from "@/components/ui/card-stack";
import { cn } from "@/lib/utils";
import FadeIn from "@/registry/components/magicui/fade-in";

export function CardStackDemo() {
  return (
    <div className="h-[40rem] flex flex-col items-center justify-center w-full">
      <FadeIn delay={0.3}>
        <h2 className="mb-4 text-center text-5xl font-bold leading-[1.2] tracking-tighter text-foreground">
          What People Are Saying
        </h2>
        <h3 className="mx-auto mb-8 max-w-lg text-balance text-center text-lg font-medium tracking-tight text-foreground/80">
          Don't just take our word for it. Here's what{" "}
          <strong>real people</strong> are saying about FEDIRALY.
        </h3>
      </FadeIn> <br /><br />
      <CardStack items={CARDS} />
    </div>
  );
}

// Small utility to highlight the content of specific section of a testimonial content
export const Highlight = ({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) => {
  return (
    <span
      className={cn(
        "font-bold bg-emerald-100 text-emerald-700 dark:bg-emerald-700/[0.2] dark:text-emerald-500 px-1 py-0.5",
        className
      )}
    >
      {children}
    </span>
  );
};

const CARDS = [
    {
      id: 0,
      name: "Jessica Nguyen",
      designation: "Tech Entrepreneur",
      content: (
        <p>
          FEDIRALY has completely transformed the way I manage my projects and teams. 
          <Highlight>Their innovative tools</Highlight>
       
        </p>
      ),
    },
    {
      id: 1,
      name: "Michael Adams",
      designation: "Marketing Strategist",
      content: (
        <p>
          In today’s fast-paced digital world, <Highlight>FEDIRALY LINK</Highlight> offers the perfect solution for staying connected with clients and partners. 

      
        </p>
      ),
    },
    {
      id: 2,
      name: "Sarah Thompson",
      designation: "Leadership Coach",
      content: (
        <p>
          FEDIRALY is the future of professional development. 

        </p>
      ),
    },
    {
      id: 3,
      name: "David Patel",
      designation: "Investor & Advisor",
      content: (
        <p>
          I’ve seen many platforms come and go, but <Highlight>FEDIRALY</Highlight> stands out as one of the best for entrepreneurs and business leaders. 

        </p>
      ),
    },
    {
      id: 4,
      name: "Lila Rivera",
      designation: "Creative Director",
      content: (
        <p>
          FEDIRALY provides the perfect balance between design and functionality. 
  
         
        </p>
      ),
    },
    {
      id: 5,
      name: "Omar Khan",
      designation: "Product Manager",
      content: (
        <p>
          FEDIRALY has revolutionized how I manage product development. 
          
         
        </p>
      ),
    },
  ];

