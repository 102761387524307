"use client";

import { useState, useEffect } from "react";
import { buttonVariants } from "@/components/ui/button";
import { cn } from "@/lib/utils";
import { ChevronRight } from "lucide-react";

export default function CTASection() {
  const [isDarkMode, setIsDarkMode] = useState(false);

  useEffect(() => {
    // Ensure this code only runs on the client-side
    if (typeof window !== "undefined") {
      const checkDarkMode = () => window.matchMedia("(prefers-color-scheme: dark)").matches;
      setIsDarkMode(checkDarkMode());

      const handleChange = (e: MediaQueryListEvent) => setIsDarkMode(e.matches);
      const mediaQuery = window.matchMedia("(prefers-color-scheme: dark)");
      mediaQuery.addEventListener("change", handleChange);

      return () => mediaQuery.removeEventListener("change", handleChange);
    }
  }, []);

  return (
    <section id="cta">
      <div className="py-14">
        <div className="container flex w-full flex-col items-center justify-center p-4">
          <div className="relative flex w-full max-w-[1000px] flex-col items-center justify-center overflow-hidden rounded-[2rem] border p-10 py-14">
            <div className="z-20 mx-auto size-24 rounded-[2rem] border bg-black/10 p-3 shadow-2xl backdrop-blur-md dark:bg-black/10 lg:size-32">
              <img src={isDarkMode ? "https://fediraly.link/assets/1.svg" : "https://fediraly.link/assets/2.svg"} alt="FEDIRALY" />
            </div>
            <div className="z-10 mt-4 flex flex-col items-center text-center text-black dark:text-white">
              <h1 className="text-3xl font-bold lg:text-4xl">
                FEDIRALY.LINK
              </h1>
              <p className="mt-2">Your personal page to show everything you are and create.</p>
              <a
                href="https://fediraly.link"
                className={cn(
                  buttonVariants({
                    size: "lg",
                    variant: "outline",
                  }),
                  "group mt-4 rounded-[2rem] px-6"
                )}
              >
                Start now!
                <ChevronRight className="ml-1 size-4 transition-all duration-300 ease-out group-hover:translate-x-1" />
              </a>
            </div>
            <div className="absolute inset-x-0 bottom-0 h-full bg-gradient-to-b from-transparent to-white to-70% dark:to-black" />
          </div>
        </div>
      </div>
    </section>
  );
}
